import InfoBox from './components/Blocks/InfoBox/InfoBox';
import icon from '@plone/volto/icons/list-bullet.svg';
import rightSVG from './icons/Accordion_open_16px.svg';
import leftSVG from './icons/Accordion_open_16px.svg';
import downSVG from './icons/Accordion_close_16px.svg';
import filterSVG from '@plone/volto/icons/filter.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import ImageCarousel from './components/Blocks/ImageCarousel/ImageCarousel';
import { pull } from 'lodash';
import TeaserBoxBody from './components/Blocks/Teaser/TeaserBoxBody';
import { default as LinkEdit } from './components/Blocks/Link/Edit';
import { default as LinkView } from './components/Blocks/Link/View';
import { default as TeaserSummaryTemplate } from './components/Blocks/Listing/TeaserSummary';
import { default as EventListingTemplate } from './components/Blocks/Listing/EventListing';
import { default as ProjectListingTemplate } from './components/Blocks/Listing/ProjectListing';
import { default as LinkListingTemplate } from './components/Blocks/Listing/LinkListing';
import { default as EmployeeListingTemplate } from './components/Blocks/Listing/EmployeeListing';
import ProjectView from './components/Theme/ProjectView';
import { Tooltips } from '@rohberg/volto-slate-glossary/components';
import QuakeSelectWidget from './components/QuakeSelectWidget/QuakeSelectWidget';

import CardSectionView from './components/Blocks/CardSection/View';
import CardSectionEdit from './components/Blocks/CardSection/Edit';
import LinkListingContent from './components/Blocks/CardSection/LinkListingContent/LinkListingContent';
import LinkListingColumnSchema from './components/Blocks/CardSection/LinkListingContent/LinkListingContentSchema';
import IconListingContent from './components/Blocks/CardSection/IconListingContent/IconListingContent';
import IconListingColumnSchema from './components/Blocks/CardSection/IconListingContent/IconListingContentSchema';

import WideGridBlockView from './components/Blocks/Grid/WideView';


import { composeSchema } from '@plone/volto/helpers';
import { columnsStylingSchemaEnhancer } from './components/Blocks/Listing/ColumnsStylingSchema';
import { listingLinkSchemaEnhancer } from './components/Blocks/Listing/LinkSchema';
import { listingPaginationSchemaEnhancer } from './components/Blocks/Listing/PaginationSchema';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';
import DatetimeWidget from 'volto-form-block/components/Widget/DatetimeWidget';

// import View from './components/Blocks/ConfigBlock/View';
// import Edit from './components/Blocks/ConfigBlock/Edit';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    navDepth: 5,
    // enableAutoBlockGroupingByBackgroundColor: false,
    isMultilingual: true,
    supportedLanguages: ['de', 'en'],
    defaultLanguage: 'de',
    matomoUrlBase: 'https://webstat.geologie.ac.at/',
    matomoSiteId: 12,
    matomoTrackerUrl: 'https://webstat.geologie.ac.at/matomo.php',
    matomo: {
      configurations: {
        disableCookies: true,
        setSecureCookie: false,
        setRequestMethod: 'POST',
      },
    },
    image_crop_aspect_ratios: [
      {
        label: '1:1',
        ratio: 1,
      },
      {
        label: '16:9',
        ratio: 16 / 9,
      },
      {
        label: '4:3',
        ratio: 4 / 3,
      },
    ],
  };

  /*
  ENHANCE A SCHEMA
    If we want to enhance a schema, we need to apply a SchemaEnhancer.
    The SchemaEnhancer here can be found in `./components/Blocks/Slate/schema.js.
    In this case, defaultStylingSchema is from volto-light, and it handles
    the application/selection of background colors.

    Since we still need to apply the new css class, we need to override
    RenderBlocks.jsx in `customizations/volto/components/theme/View/`.

    import { composeSchema } from '@plone/volto/helpers';
    import { slateSchemaEnhancer } from './components/Blocks/Slate/schema';
    import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';

    config.blocks.blocksConfig.slate.schemaEnhancer =
      composeSchema(slateSchemaEnhancer, defaultStylingSchema);
  */

  /*
  ASSIGN A NEW BLOCK
    To assign a new block, we will add it to blocksConfig.
    The necessary code for the example custom block being registered here can be
    found in `./components/Blocks/ConfigBlock`.

    import View from './components/Blocks/ConfigBlock/View';
    import Edit from './components/Blocks/ConfigBlock/Edit';

    config.blocks.blocksConfig.myCustomBlock = {
      id: 'myCustomBlock',
      title: 'My Custom Block',
      edit: Edit,
      view: View,
      icon: icon,
      group: 'text',
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    };
  */

  // Add more available heading tags to Heading Block
  config.blocks.blocksConfig.heading.allowed_headings = [
    'h2',
    'h3',
    'h4',
    'h5',
  ];
  config.blocks.blocksConfig.heading.variations = [
    {
      id: 'default',
      title: 'Default',
      isDefault: true,
    },
    {
      id: 'page_subtitle',
      title: 'Page Subtitle',
      isDefault: false,
    },
    {
      id: 'full_layout_width',
      title: 'Wide',
      isDefault: false,
    }
  ];

  // Add more available background colors to Slate Block (the base text block)
  config.blocks.blocksConfig.slate.colors = [
    ...config.blocks.blocksConfig.slate.colors,
    {
      name: 'lime',
      label: 'Lime',
    },
    {
      name: 'red',
      label: 'Red',
    },
  ];

  // Add InfoBox block config
  config.blocks.blocksConfig.infoBox = {
    id: 'infoBox',
    title: 'Info Box',
    edit: InfoBox,
    view: InfoBox,
    icon: icon,
    group: 'common',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  // Replace eeacms accordion icons
  config.blocks.blocksConfig.accordion.titleIcons = {
    closed: { leftPosition: rightSVG, rightPosition: leftSVG },
    opened: { leftPosition: downSVG, rightPosition: downSVG },
    filtered: { leftPosition: clearSVG, rightPosition: clearSVG },
    unfiltered: { leftPosition: filterSVG, rightPosition: filterSVG },
    size: '24px',
    iconComponent: 'VoltoIcon', // other option is SemanticIcon
  };

  // volto-slider-block variation
  config.blocks.blocksConfig.slider.variations = [
    {
      id: 'default',
      title: 'Default',
      isDefault: true,
    },
    {
      id: 'image-carousel',
      title: 'Image Carousel',
      view: ImageCarousel,
      isDefault: false,
      schemaEnhancer: ({ schema, FormData, intl }) => {
        pull(schema.properties.slides.schema.fieldsets[0].fields, 'href');
        pull(schema.properties.slides.schema.fieldsets[0].fields, 'head_title');
        pull(schema.properties.slides.schema.fieldsets[0].fields, 'title');
        return schema;
      },
    },
  ];

  // volto teaser block variation
  config.blocks.blocksConfig.teaser.variations = [
    {
      id: 'default',
      title: 'Default',
      isDefault: false,
    },
    {
      id: 'teaser-box',
      title: 'Teaser Box',
      isDefault: true,
      template: TeaserBoxBody,
      schemaEnhancer: ({ schema, FormData, intl }) => {
        schema.properties.styles.schema.properties.boxsize = {
          title: 'Box Size',
          choices: [
            ['small', 'small'],
            ['large', 'large'],
          ],
          default: 'small',
          isRequired: true
        };
        pull(schema.fieldsets[0].fields, 'head_title');
        pull(schema.properties.styles.schema.fieldsets[0].fields, 'align');
        pull(
          schema.properties.styles.schema.fieldsets[0].fields,
          'backgroundColor',
        );
        schema.properties.styles.schema.fieldsets[0].fields.push('boxsize');

        return schema;
      },
    },
  ];

  // Add Link block config
  config.blocks.blocksConfig.link = {
    id: 'link',
    title: 'Link',
    edit: LinkEdit,
    view: LinkView,
    icon: icon,
    group: 'common',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  // volto teaser and infobox listing block variations
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'teaser-listing',
      title: 'Teaser Listing',
      isDefault: false,
      template: TeaserSummaryTemplate,
    },
    {
      id: 'news-listing',
      title: 'News Listing',
      isDefault: false,
      template: TeaserSummaryTemplate,
    },
    {
      id: 'event-listing',
      title: 'Event Listing',
      isDefault: false,
      template: EventListingTemplate,
    },
    {
      id: 'project-listing',
      title: 'Project Listing',
      isDefault: false,
      template: ProjectListingTemplate,
    },
    {
      id: 'link-listing',
      title: 'Link Listing',
      isDefault: false,
      template: LinkListingTemplate,
    },
    {
      id: 'employee-listing',
      title: 'Employees Listing',
      isDefault: false,
      template: EmployeeListingTemplate,
    },
  ];

  // enhance listing schema for one or 2 columns
  // enhance listing schema to add optional link on the bottom
  config.blocks.blocksConfig.listing.schemaEnhancer =
    composeSchema(columnsStylingSchemaEnhancer, listingPaginationSchemaEnhancer,
        listingLinkSchemaEnhancer, defaultStylingSchema);

  config.settings.styleClassNameExtenders = [
      ...config.settings.styleClassNameExtenders,
      ({ block, content, data, classNames }) => {
        let styles = [];
        if (data.columns === 'two') {
            styles.push('two-columns')
        }
        return [...classNames, ...styles];
      },
  ];

  config.views.contentTypesViews['geosphere.project'] = ProjectView;

  /// glossary
  config.settings = {
    ...config.settings,
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '/de/glossartestbereich',
        component: Tooltips,
      },
    ],
  };

  /// quake form
  config.blocks.blocksConfig.form.additionalFields.push({
    id: 'Beben',
    label: 'Beben',
    component: QuakeSelectWidget,
    isValid: (formData, name) => true,
  });
  // datetime form field 
  config.blocks.blocksConfig.form.additionalFields.push({
    id: 'Datetime',
    label: 'Datetime',
    component: DatetimeWidget,
    isValid: (formData, name) => true,
  });


  // register Card Section Block
  config.blocks.blocksConfig.cardSection = {
    id: 'cardSection',
    title: 'cardSection',
    edit: CardSectionEdit,
    view: CardSectionView,
    icon: icon,
    group: 'text',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.cardSection.variations = [
    {
      id: 'icon_listing',
      title: 'Icon Listing',
      isDefault: true,
      template: IconListingContent,
      columnSchema: IconListingColumnSchema
    },
    {
      id: 'link_listing',
      title: 'Link Listing',
      isDefault: false,
      template: LinkListingContent,
      columnSchema: LinkListingColumnSchema
    },
  ];

  // add more allowed block types to grid block
  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    'image',
    'listing',
    'slate',
    'teaser',
    'heading',
    'accordion'
  ]

  config.blocks.blocksConfig.gridBlock.variations = [
    {
      id: 'default',
      title: 'Default',
      isDefault: true,
    },
    {
      id: 'wide',
      title: 'Full width',
      isDefault: false,
      view: WideGridBlockView
    },
  ];

  return config;
};

export default applyConfig;
